

function Process() {
    return (
        <div className="process">


<div class="timeline">
    <div class="process-container">
        <h1>Application Process</h1>
        <ul class="steps">
            <li class="step">
                <div class="circle">1</div>
                <div class="description">
                    <h3>Application</h3>
                    <p>Fill application and apply online.</p>
                </div>
            </li>
            <li class="step">
                <div class="circle">2</div>
                <div class="description">
                    <h3>Screening</h3>
                    <p>We will check if your resume qualifies. </p>
                </div>
            </li>
            <li class="step">
                <div class="circle">3</div>
                <div class="description">
                    <h3>Interview</h3>
                    <p>You will receive an invitation for the assessment in one of our open days.</p>
                </div>
            </li>
            <li class="step">
                <div class="circle">4</div>
                <div class="description">
                    <h3>Training</h3>
                    <p>If you succeed during assessment, you will receive a link for registration to our training courses in Vilnius.</p>
                </div>
            </li>
            <li class="step">
                <div class="circle">5</div>
                <div class="description">
                    <h3>First flight</h3>
                    <p>Congratulation! You are part of our crew family.</p>
                </div>
            </li>
        </ul>
    </div>
</div>




</div>

    );
}

export default Process;