
import React, { useState, useEffect } from 'react';
import {
} from 'cdbreact';
import axios from 'axios';
import { Button } from 'react-bootstrap';



function Apps() {
  

  /* get data from fRoM DB */
  const [emails, setEmails] = useState([]);
  const [url, setUrl]= useState('');


  useEffect(() => {
    axios.get('https://api.flysaturn.co/Emails')

      .then(emails => setEmails(emails.data))

      .catch(err => alert(err));
  }, [])

  return (
    <div>
    <h1 className='h1'>Emails : </h1>
    <table className="table table-striped table-hover table-responsive">
      <thead >
        <th>ID</th>
        <th>Name   </th>
        <th>Email</th>
        <th>Message</th>
      </thead>
      <tbody>
        {
          emails.map((data, i) => (
            <tr key={i}>
              <td >{data.ID}</td>
              <td>{data.name}</td>
              <td>{data.email}</td>
              <td>{data.message}</td>
              
              <td>
                

              

                <Button onClick={() => setUrl("/Reply/"+data.email)}    href={url} className='btn btn-success'> Reply </Button>


              </td>
            </tr>

          ))}
      </tbody>
    </table>
    </div>
  );
};

export default Apps;