import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Image from 'react-bootstrap/Image';
import logo from 'C:/Users/Top Tech/flysaturn/frontend/flysaturn/src/assets/Logo.png';
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useLocation } from 'react-router-dom';







function NavScrollExample() {
  const location = useLocation()

  const { isAuthenticated, user } = useKindeAuth();
  
/*check if logged in and send user to corect page */
  var n = "login";

  
    isAuthenticated && (
      n = "Dash"
    )
  
  /*send the user to corredct contact page */
  var x = '/#contact';
  
    location.pathname == '/Dash' && (
     x='/Contacts'
    )

  





  return (
    <Navbar fixed="top" expand="lg" className="bg-body-tertiary sticky-top navbar">
      <Container fluid className='container'>
        <Navbar.Brand href="#"><Image width="145"
          height="40"
          className="d-inline-block align-top img-thumbnail"
          alt="React Bootstrap logo" src={logo}></Image>  </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto ms-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
            <Nav.Link href="/home" className='link'>Home</Nav.Link>
            <Nav.Link href="/#careers" className='link'>Careers</Nav.Link>
            <Nav.Link href={x} className='link'> Contact </Nav.Link>
            <Nav.Link href={n} className='link'> Admin </Nav.Link>
          </Nav>
           
          {!isAuthenticated || ( isAuthenticated && (location.pathname === '/' || location.pathname === '/home')) ? (
        <Button className='loginB' href="/AppForm">
          <p>Apply Now</p>
        </Button>
      ) : null}
          {
            isAuthenticated && (

              <p>{user.given_name}</p>
            )
          }
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavScrollExample;