import React, { useEffect, useState } from 'react'

function Test_backend_conn() {
    const  [data, setData] = useState({});
    useEffect(()=>{
        fetch("https://api.flysaturn.co/nigga")
        .then(res => res.json())
        .then(data => setData(data))
        .catch(err=> alert("error: "+err))

        
    },[])

  return (
    <div>
     <h1> this is ur data from backend</h1>
     <p>{data.name}</p>
     <p>{data.last}</p>
    </div>
  )
}

export default Test_backend_conn
