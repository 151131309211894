import { Image } from "react-bootstrap";
import cabin from "C:/Users/Top Tech/flysaturn/frontend/flysaturn/src/assets/cabin2.png";
import senior from "C:/Users/Top Tech/flysaturn/frontend/flysaturn/src/assets/senior2.png";
function Options() {
    return (
        <div id="careers" className="options">

            <h1 >Available Positions</h1>
            <p> We are looking for ambitious Cabin Crew to support the growth and further development of the company. Join our professional and friendly team!
                The company creates a supportive environment and conditions to grow, both professionally and personally.</p>

            <div className='lists'>

                <Image className="senior" width="auto" height="400" src={cabin} alt="Senior cabin pic" />

                <div className='pos1'>
                    <h2>Senior Cabin Crew </h2> <h3>- what you need</h3>
                    <ul className="list">
                        <li>Age: at least 17 years old.</li>
                        <li>Minimum secondary education degree or any school certififcates</li>
                        <li>Fluency in English, both spoken and written.</li>
                        <li>Cabin Crew Attestation.</li>
                        <li>Valid passport </li>
                        <li>Valid EASA Cabin Crew Medical report.</li>
                        <li>Valid Criminal Record.</li>
                        <li>Ability to swim unassisted.</li>
                        <li>No visible tattoos/piercings.</li>
                        <li>Flexibility – we operate flights 24 hours a day, <br /> 365 days a year. </li>
                    </ul>
                </div>



                <Image className="cabin" width="auto" height="400" src={senior} alt="Cabin pic" />

                <div className='pos2'>
                    <h2>Cabin Crew </h2><h3> - what you need </h3>
                    <ul className="list">

                        <li>Age: at least 17 years old.</li>
                        <li>Minimum secondary education degree or any school certififcates</li>
                        <li>Fluency in English, both spoken and written.</li>
                        <li>Cabin Crew Attestation.</li>
                        <li>Valid passport </li>
                        <li>Valid EASA Cabin Crew Medical report.</li>
                        <li>Valid Criminal Record.</li>
                        <li>Ability to swim unassisted.</li>
                        <li>No visible tattoos/piercings.</li>
                        <li>Flexibility – we operate flights 24 hours a day, <br /> 365 days a year. </li>
                    </ul>
                </div>

            </div>

        </div>
    );
}

export default Options;