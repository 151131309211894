import Image from 'react-bootstrap/Image';
import image from 'C:/Users/Top Tech/flysaturn/frontend/flysaturn/src/assets/flight.jpg';
import image1 from 'C:/Users/Top Tech/flysaturn/frontend/flysaturn/src/assets/flight1.jpg';
import { Helmet } from 'react-helmet';

function HomePage() {
    return (
        <body className='home'>
  <Helmet>
      <title>FlySaturn</title>

      </Helmet>

            <h5 className='frst'>Become Our Cabin Crew</h5>
            <picture>
                <source
                    media="(max-width: 768px)"
                    srcset={image1} alt="cabin-girl-mobile" fluid />
                <source
                    media="(min-width: 768px)"
                    srcset={image} />
                <Image className='crewimage'
                    src="images/kitten-curled.png"
                    alt="cabin-girl" fluid />
            </picture>
            {/* <Image className='crewimage' src={image} alt="cabin-girl" fluid />*/}
            <p>.</p>
            <p></p>
            <p></p>
            <p></p>
            <div className='why'>
                <h5 className='frst2' >Why </h5><h5 className='bck2'>FlySaturn ?</h5>
            </div>
            <p></p>
            <p className='text' >We are a globally leading A320 Narrow-body ACMI operator and charter airline, with long-term and well-known clients and business partners around the world. With airlines in Lithuania and Malta, we’ve experienced significant growth in recent years. With a diverse team of approximately 1,800 professionals representing over 60 different nationalities and a fleet of over 50 aircraft serving commercial ACMI and charter routes across five continents, we continue to expand our fleet and destinations’ network and actively recruit new team members. </p>



        </body>
    )

}

export default HomePage;