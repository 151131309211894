import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { Image } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

function DetailsPage() {
  const { id } = useParams();
  const [applicant, setApplicant] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios.get(`https://api.flysaturn.co/details/${id}`)
      .then(response => {
        setApplicant(response.data[0]); // Assuming the response is an array
        setLoading(false);
      })
      .catch(err => {
        setError(err.message);
        setLoading(false);
      });
  }, [id]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  // Base URL for file downloads
  const baseURL = 'https://api.flysaturn.co/downloads';

  return (
    <div className='details'>
<Helmet>
      <title>Details</title>

      </Helmet>
      {applicant ? (
        <div>
          <h1>Applicant Details for : {applicant.name} </h1>

          <Image width="145" height="40" className="d-inline-block align-top img-thumbnail" alt="applicant portrait" src={`${baseURL}/${applicant.image}`}></Image>

          <div className='det'>
            <p><b>ID: </b>{applicant.ID}</p>
            <p><b>Name:</b> {applicant.name}</p>
            <p><b>Last Name: </b>{applicant.last_name}</p>
            <p><b>Email: </b>{applicant.email}</p>
            <p><b>Gender:</b> {applicant.gender}</p>
            <p><b>Status:</b> {applicant.status}</p>
            <p><b>phone: </b>{applicant.phone}</p>
            <p><b>Date of birth:</b> {applicant.dob}</p>
            <p><b>height : </b>{applicant.height}</p>
            <p><b>Over 18 years old:</b> {applicant.agee}</p>
            <p><b>Can Swim:</b> {applicant.swim}</p>
            <p><b>When will be ready for 6 week training:</b> {applicant.training}</p>
            <p><b>Tattoos: </b>{applicant.tattoos}</p>
            <p><b>Previous work accidents:</b> {applicant.accident}</p>
            <p><b>Health status: </b>{applicant.health}</p>
            <p><b>Age : </b>{applicant.age}</p>
            <p><b>Living in malaysia ? :</b> {applicant.living}</p>

          </div>
          {/* Buttons to download files */}
          <div>
            <h2>Documents</h2>
            {applicant.image && (
              <a href={`${baseURL}/${applicant.image}`} download>
                <Button className='btn-success'>Download Image</Button>
              </a>
            )}
            {applicant.diploma && (
              <a href={`${baseURL}/${applicant.diploma}`} download>
                <Button className='btn-warning'>Download Diploma</Button>
              </a>
            )}
            {applicant.resume && (
              <a href={`${baseURL}/${applicant.resume}`} download>
                <Button>Download Resume</Button>
              </a>
            )}
          </div>
        </div>
      ) : (
        <p>No applicant found</p>
      )}
    </div>
  );
}

export default DetailsPage;
