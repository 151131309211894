import React from 'react';
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';



function Login() {

  const { login, isAuthenticated } = useKindeAuth();



  return (

    <body className='logb'>

<Helmet>
      <title>Login</title>

      </Helmet>

      {
        !isAuthenticated && (
          <div>
            <h1>You are not logged in </h1>
            <Button className='lgbtn' onClick={login}> Login</Button>

          </div>
        )
      }{
        isAuthenticated && (

          <h1>YOU ARE LOGGED IN </h1>

        )
      }
    </body>
  );
}

export default Login;