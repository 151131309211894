import Card from 'react-bootstrap/Card';
import icon1 from 'C:/Users/Top Tech/flysaturn/frontend/flysaturn/src/assets/check.svg'
import star from 'C:/Users/Top Tech/flysaturn/frontend/flysaturn/src/assets/star.svg'
import globe2 from 'C:/Users/Top Tech/flysaturn/frontend/flysaturn/src/assets/globe2.svg'
import globe from 'C:/Users/Top Tech/flysaturn/frontend/flysaturn/src/assets/globe.svg'
import ppl from 'C:/Users/Top Tech/flysaturn/frontend/flysaturn/src/assets/ppl.svg'
import payment from 'C:/Users/Top Tech/flysaturn/frontend/flysaturn/src/assets/payment.svg'
import CountUp from 'react-countup';
import VisibilitySensor from "react-visibility-sensor";


function TextExample() {
  return (
    <body >
    <div className='cards'>
      <Card className='card1' broder="primary" style={{ width: '23rem' }}>
        <Card.Img className='icon' variant="top" src={icon1} style={{ width: '30px' }} />
        <Card.Body>

          <Card.Title className='ctitle'> Indefinite Contracts</Card.Title>

          <Card.Text classname='ctex'>
            Enjoy the security of a long-term contract, providing stability and peace of mind for your career.
          </Card.Text>

        </Card.Body>
      </Card>
      <Card className='card1' style={{ width: '23rem' }}>
        <Card.Img className='icon' variant="top" src={payment} style={{ width: '30px' }} />
        <Card.Body>

          <Card.Title className='ctitle'> Competitive Payment</Card.Title>

          <Card.Text classname='ctex'>
            Earn competitive payment as a cabin crew, ensuring a stable and rewarding income for your hard work and dedication.
          </Card.Text>

        </Card.Body>
      </Card>
      <Card className='card1' style={{ width: '23rem' }}>
        <Card.Img className='icon' variant="top" src={globe2} style={{ width: '30px' }} />
        <Card.Body>

          <Card.Title className='ctitle'>
            HOTAC</Card.Title>

          <Card.Text classname='ctex'>
            Experience convenience and comfort during layovers with our provided accommodation and transportation.
          </Card.Text>

        </Card.Body>
      </Card>
      <Card className='card1' style={{ width: '23rem' }}>
        <Card.Img className='icon' variant="top" src={star} style={{ width: '30px' }} />
        <Card.Body>

          <Card.Title className='ctitle'>
            Insurance</Card.Title>

          <Card.Text classname='ctex'>
            Rest easy knowing that you are protected by comprehensive travel insurance coverage, valid anywhere in the world.
          </Card.Text>

        </Card.Body>
      </Card>
      <Card className='card1' style={{ width: '23rem' }}>
        <Card.Img className='icon' variant="top" src={ppl} style={{ width: '30px' }} />
        <Card.Body>

          <Card.Title className='ctitle'>
            Cultures</Card.Title>

          <Card.Text classname='ctex'>
            Embrace the advantages of working in a multicultural environment and a vibrant workplace.
          </Card.Text>

        </Card.Body>
      </Card>
      <Card className='card1' style={{ width: '23rem' }}>
        <Card.Img className='icon' variant="top" src={globe} style={{ width: '30px' }} />
        <Card.Body>

          <Card.Title className='ctitle'>Travel The World</Card.Title>

          <Card.Text classname='ctex'>
            Enjoy your opportunity to travel and explore new locations. Pack your bags and join the adventure!
          </Card.Text>

        </Card.Body>
      </Card>
    </div>
    <VisibilitySensor partialVisibility offset={{ top: 50 }} >
    {({isVisible}) =>(

    <div className='spacer'>
      
      {isVisible ? 
        <div className='conter1'>
          <CountUp start={0}
            end={134}
            duration={2.75} />
          <p>+ Countries</p>
        </div> : <div className='conter1'>
         
          <p> 134 + Countries</p>
        </div>}

        {isVisible ? 
        <div className='conter2'>
          <CountUp start={0}
            end={1023}
            duration={2.75} />
          <p>+ Cabin Crew</p>
        </div>:   <div className='conter2'>
          
          <p>1023 + Cabin Crew</p>
        </div>}

      </div>)}
     
      </VisibilitySensor>
    </body>
  );
}

export default TextExample;