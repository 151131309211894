import "react-datepicker/dist/react-datepicker.css";
import { useState } from 'react';
import React, { useRef } from 'react';
import axios from "axios";
import { Helmet } from 'react-helmet';

function MyForm() {
  /* Upload files */
  const [diploma, setDiploma] = useState(null);
  const [image, setImage] = useState(null);
  const [resume, setResume] = useState(null);

  /* Form data */
  const [name, setName] = useState('');
  const [last, setLast] = useState('');
  const [email, setEmail] = useState('');
  const [gender, setGender] = useState('male');
  const [dob, setDob] = useState('');
  const [phone, setPhone] = useState('');
  const [height, setHeight] = useState('');
  const [agee, setAgee] = useState('');
  const [swim, setSwim] = useState('');
  const [training, setTraining] = useState('');
  const [tattoos, setTattoos] = useState('');
  const [accident, setAccident] = useState('');
  const [health, setHealth] = useState('');
  const [age, setAge] = useState('');
  const [living, setLiving] = useState('');

  //email
  const [receiver, setReceiver] = useState('');
  const [message, setMessage] = useState('');

  /* Validation errors */
  const [errors, setErrors] = useState({});

  /* Handle file uploads */
  const upload = async () => {
    const formData = new FormData();
    formData.append('image', image);
    formData.append('diploma', diploma);
    formData.append('resume', resume);

    try {
      const response = await axios.post('https://api.flysaturn.co/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('Success:', response.data);
    } catch (error) {
      console.error('Error uploading files:', error);
    }
  }

  /* Form submission to database */
  const AddtoDB = async () => {
    try {
      const response = await axios.post('https://api.flysaturn.co/AppForm', {
        name, last, email, gender, phone, dob, height, agee, swim, training, tattoos, accident, health, age, living
      });
      console.log("Database response:", response.data);
      console.log("Submitted Successfully");
    } catch (error) {
      console.error("Error adding to database:", error);
      throw error;
    }
  };

  /* Email JS */
  const form = useRef();

  const validateForm = () => {
    const newErrors = {};

    // Validation rules
    if (!name.trim()) newErrors.name = "First name is required";
    if (!last.trim()) newErrors.last = "Last name is required";
    if (!email.includes('@')) newErrors.email = "Valid email is required";
    if (!phone.trim()) newErrors.phone = "Phone number is required";
    if (!dob.trim()) newErrors.dob = "Date of birth is required";
    if (!height) newErrors.height = "Please select your height";
    if (!agee) newErrors.agee = "Please confirm your age";
    if (!swim) newErrors.swim = "Please confirm if you can swim";
    if (!age) newErrors.age = "Please provide your age";
    if (!living) newErrors.living = "Please confirm if you are based in Malaysia";

    //validation for file not empty & size & type
    const maxFileSize = 2 * 1024 * 1024;

    if (!image) {
      newErrors.image = "Please upload an image";
    } else if (!["image/jpeg", "image/png"].includes(image.type)) {
      newErrors.image = "Only JPG/PNG images are allowed";
    } else if (image.size > maxFileSize) {
      newErrors.image = "Image file size must be less than 2MB";
    }

    if (!diploma) {
      newErrors.diploma = "Please upload your diploma";
    } else if (!["application/pdf"].includes(diploma.type)) {
      newErrors.diploma = "Diploma must be a PDF";
    } else if (diploma.size > maxFileSize) {
      newErrors.diploma = "Diploma file size must be less than 2MB";
    }

    if (!resume) {
      newErrors.resume = "Please upload your resume";
    } else if (!["application/pdf", "application/msword"].includes(resume.type)) {
      newErrors.resume = "Resume must be a PDF or DOC file";
    } else if (resume.size > maxFileSize) {
      newErrors.resume = "Resume file size must be less than 2MB";
    }


    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const sendEmail = async (e) => {
    e.preventDefault();

    // Form validation before submission
    if (!validateForm()) {
      alert("Missing REQUIRED Fields");
      return; //Abort if a field is missing
    }

    try {
      await AddtoDB(); // Ensure data is added to the DB
    } catch (error) {
      console.log("Error submitting data to the database: " + error.message);
      return; // Abort if DB submission fails
    }

    try {
      await upload(); // Ensure file upload completes
    } catch (error) {
      console.error('Error uploading files:', error);
      return; // Abort if upload fails
    }

    if (message && receiver) {
      axios.post('https://api.flysaturn.co/Reply', { receiver, message })
        .then(() => alert('Your application has been submitted successfully.'),
        )
        .catch(err => console.log(err));
    }
  };


function HandleClick(e){
  setMessage('A new appalication from : ' + name + ' ' + last);
  setReceiver('recruitmentxc@flysaturn.co','confirmsb@flysaturn.co');
  sendEmail(e);
}



  return (
    <div className="myform">
      <Helmet>
      <title>Application Form</title>

      </Helmet>
      <h1 className="t">Application Form</h1>
      <div className="formbold-main-wrapper">

        <div className="formbold-form-wrapper">

          <form ref={form} onSubmit={HandleClick} >
            <div className="formbold-input-flex">
              <div>
                <label htmlFor="firstname" className="formbold-form-label"> First Name </label>
                <input
                  type="text"
                  name="firstname"
                  id="firstname"
                  placeholder="Your first name"
                  className="formbold-form-input"
                  onChange={e => setName(e.target.value)}
                />
                {errors.name && <span className="error-message">{errors.name}</span>}              </div>

              <div>
                <label htmlFor="lastname" className="formbold-form-label"> Last Name </label>
                <input
                  type="text"
                  name="lastname"
                  id="lastname"
                  placeholder="Your last name"
                  className="formbold-form-input"
                  onChange={e => setLast(e.target.value)}
                />
                {errors.last && <span className="error-message">{errors.last}</span>}

              </div>
            </div>

            <div className="formbold-input-flex">
              <div>
                <label htmlFor="email" className="formbold-form-label"> Email </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="example@email.com"
                  className="formbold-form-input"
                  onChange={e => setEmail(e.target.value)}
                />
                {errors.email && <span className="error-message">{errors.email}</span>}

              </div>

              <div>
                <label className="formbold-form-label">Gender</label>

                <select className="formbold-form-input" name="Gender" id="occupation" onChange={e => setGender(e.target.value)}>
                  <option selected value="male">Male</option>
                  <option value="female">Female</option>
                </select>

              </div>
            </div>

            <div className="formbold-mb-3 formbold-input-wrapp">
              <label htmlFor="phone" className="formbold-form-label"> Phone </label>
              <input
                type="number"
                name="phone"
                id="phone"
                placeholder="Phone number"
                className="formbold-form-input"
                onChange={e => setPhone(e.target.value)}
              />
              {errors.phone && <span className="error-message">{errors.phone}</span>}

            </div>

            <div className="formbold-mb-3">
              <label htmlFor="dob" className="formbold-form-label"> Date Of Birth </label>
              <input type="text" name="dob" id="dob" className="formbold-form-input" onChange={e => setDob(e.target.value)} />
              {errors.dob && <span className="error-message">{errors.dob}</span>}

            </div>

            <div className="formbold-mb-3 radiof">
              <label htmlFor="age" className="formbold-form-label"> Are ou approximately between 155cm and 188cm in height ? </label>

              <label>Yes</label>
              <input
                type="radio"
                name="height"
                value='Yes'
                className="formbold-form-input radio"

                onChange={e => setHeight(e.target.value)}
              />
              <label>No</label>
              <input
                type="radio"
                name="height"
                value='No'

                id="age"
                className="formbold-form-input radio"
                onChange={e => setHeight(e.target.value)}
              />
              {errors.height && <span className="error-message">{errors.height}</span>}

            </div>


            <div className="formbold-mb-3">
              <label htmlFor="age" className="formbold-form-label"> Are you over the age of 17 ? </label>
              <input
                type="radio"
                name="agee"
                value='Yes'
                id="age"
                className="formbold-form-input"
                onChange={e => setAgee(e.target.value)}
              />Yes

              <input
                type="radio"
                name="agee"
                value='No'
                id="age"
                className="formbold-form-input"
                onChange={e => setAgee(e.target.value)}
              />No
              {errors.agee && <span className="error-message">{errors.agee}</span>}

            </div>


            <div className="formbold-mb-3 ">
              <label htmlFor="age" className="formbold-form-label"> Can you swim at least 25 meters unaided ? </label>
              <input
                type="radio"
                name="swim "
                value='Yes'
                id="age"
                className="formbold-form-input"
                onChange={e => setSwim(e.target.value)}
              />Yes

              <input
                type="radio"
                name="swim"
                value='No'
                id="age"
                className="formbold-form-input"
                onChange={e => setSwim(e.target.value)}
              />No
              {errors.swim && <span className="error-message">{errors.swim}</span>}

            </div>


            <div className="formbold-mb-3">
              <label htmlFor="address" className="formbold-form-label"> When can u join a 6-week trainig course ? </label>

              <input
                type="text"
                name="address"
                id="address"
                placeholder="Details"
                className="formbold-form-input formbold-mb-3"
                onChange={e => setTraining(e.target.value)}
              />

            </div>

            <div className="formbold-mb-3">
              <label htmlFor="message" className="formbold-form-label">
                Do you any tattoos especially in the (visible) areas of your body ? if yes please provide more information
              </label>
              <textarea
                type="text"
                rows="6"
                name="message"
                id="message"
                className="formbold-form-input"
                placeholder="more details"
                onChange={e => setTattoos(e.target.value)}
              ></textarea>
            </div>

            <div className="formbold-mb-3">
              <label htmlFor="message" className="formbold-form-label">
                Have you ever suffered from an industrial illness or work place accident ? if yes please provide details
              </label>
              <textarea
                type="text"

                rows="6"
                name="message"
                id="message"
                className="formbold-form-input"
                placeholder="more details"
                onChange={e => setAccident(e.target.value)}
              ></textarea>
            </div>

            <div className="formbold-mb-3">
              <label htmlFor="message" className="formbold-form-label">
                Would you like to declare any other health issues or medical conditions ? if yes please provide details
              </label>
              <textarea
                type="text"

                rows="6"
                name="message"
                id="message"
                className="formbold-form-input"
                placeholder="more details"
                onChange={e => setHealth(e.target.value)}
              ></textarea>
            </div>


            <div className="formbold-mb-3">
              <label htmlFor="message" className="formbold-form-label">
                the maximum age to be a FlySaturn cabin crew is 43 years old at the time of application what is your age ?
              </label>
              <textarea
                type="number"

                rows="6"
                name="message"
                id="message"
                className="formbold-form-input"
                placeholder="more details"
                onChange={e => setAge(e.target.value)}
              ></textarea>
              {errors.age && <span className="error-message">{errors.age}</span>}

            </div>


            <div className="formbold-mb-3 ">
              <label htmlFor="age" className="formbold-form-label"> are you currently based in Malaysia  ? </label>
              <input
                type="radio"
                name="living"
                value='Yes'
                id="age"
                className="formbold-form-input"
                onChange={e => setLiving(e.target.value)}
              />Yes

              <input
                type="radio"
                name="living"
                value='No'
                id="age"
                className="formbold-form-input"
                onChange={e => setLiving(e.target.value)}
              />No
              {errors.living && <span className="error-message">{errors.living}</span>}

            </div>


            <div className="formbold-mb-3">
              <label htmlFor="message" className="formbold-form-label">
                Cover Letter
              </label>
              <textarea
                rows="6"
                name="message"
                id="message"
                className="formbold-form-input"
              ></textarea>
            </div>

            <div className="formbold-form-file-flex">
              <label htmlFor="upload" className="formbold-form-label">
                Upload Diploma
              </label>
              <input
                type="file"
                name="diploma"
                id="upload"
                className="formbold-form-file"
                onChange={(e) => setDiploma(e.target.files[0])}
              />
              {errors.diploma && <span className="error-message">{errors.diploma}</span>}


              <label className="formbold-form-label">
                Upload Portrait
              </label>
              <input
                className="formbold-form-file"
                type="file"
                name="image"
                onChange={(e) => setImage(e.target.files[0])}
              />
              {errors.image && <span className="error-message">{errors.image}</span>}



              <label htmlFor="upload" className="formbold-form-label">
                Upload Resume
              </label>
              <input
                type="file"
                name="resume"
                id="upload"
                className="formbold-form-file"
                onChange={(e) => setResume(e.target.files[0])}
              />
              {errors.resume && <span className="error-message">{errors.resume}</span>}


            </div>
            <button type="submit" value="Send" onClick={(e)=>HandleClick(e)} className="formbold-btn">Apply Now</button>


          </form>
        </div>
      </div>







    </div>
  )
}
export default MyForm;
