import { useState } from 'react';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';





function Reply() {
    const navigate = useNavigate();

    const { email } = useParams();

    const [receiver, setReceiver] = useState('');
    const [message, setMessage] = useState('');




    /*Nodemailer*/

    function sendMail() {
        if (message && receiver) {
            axios.post('https://api.flysaturn.co/Reply', { receiver, message })
                .then(() => alert('success'),
                    navigate('/Dash/#emails')
                )
                .catch(err => console.log(err));
        }
        return alert('all fields must be filled');
    }

    return (
        <div class="formbold-form-wrapper">

            <Helmet>
                <title>Message Reply</title>

            </Helmet>
            <div class="formbold-main-wrapper">

                <h1>Reply </h1><br />
                <form onSubmit={sendMail} >

                    <div class="formbold-mb-3">

                        <label for="Receiver" class="formbold-form-label">
                            Receiver
                        </label>
                        <input
                            defaultValue={email}
                            name="Receiver"
                            id="Receiver"
                            class="formbold-form-input"
                            onChange={e => setReceiver(e.target.value)}

                        />

                        <p>{receiver}</p>

                        <label for="message" class="formbold-form-label">
                            Message
                        </label>
                        <textarea
                            rows="6"
                            name="message"
                            id="message"
                            class="formbold-form-input"
                            onChange={e => setMessage(e.target.value)}

                        />

                        <p>{message}</p>
                    </div>

                </form >
                <button type="submit" value="Send" onClick={() => sendMail()} class="formbold-btn">Send </button>

            </div>
        </div>
    );
}

export default Reply;