import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function Apps() {
  const [receiver, setReceiver] = useState('');
  const [message, setMessage] = useState('');
  const [id, setId] = useState('');
  const [status, setStatus] = useState('Processing');
  const [applicants, setApplicants] = useState([]);
  const [applicantName, setApplicantName] = useState('');

  const navigate = useNavigate(); // Hook to navigate programmatically

  // Get table data from database
  useEffect(() => {
    axios.get('https://api.flysaturn.co/applications')
      .then(response => {
        if (Array.isArray(response.data)) {
          setApplicants(response.data); // Only set applicants if it's an array
        } else {
          console.error('Data is not an array:', response.data);
          alert('Unexpected data format from the server');
        }
      })
      .catch(err => {
        console.error('Error fetching data from DB:', err);
        alert('Error fetching data from DB');
      });
  }, []);

  // send email
  const sendMail = () => {
    axios.post('https://api.flysaturn.co/Response', { receiver, message, applicantName })
      .then(response => {
        console.log('Mail sent successfully:', response.data);
        alert('Mail sent successfully');
      })
      .catch(error => {
        console.error('Error sending mail:', error);
        alert('Error sending mail');
      });
  };


  // update database with status
  const updateDB = () => {
    sendMail();
    axios.put('https://api.flysaturn.co/Dash', { id, status })
      .then(() => alert("Status Updated successfully"))
      .catch(err => alert('Error updating status'));
  };

  //click on row takes u to applicant page
  const handleRowClick = (data) => {
    navigate(`/details/${data.ID}`); // Navigate to details page with ID
  };

  //change states of variables when status is changed
  const handleSelectChange = (data) => (e) => {
    const newStatus = e.target.value;
    setStatus(newStatus);
    setId(data.ID);
    setReceiver(data.email);
    setApplicantName(data.name);
    setMessage(newStatus);
  };

  return (
    <div>
    <h1 className='h1'>Applicants :</h1>
    <table className="table table-striped table-hover ">
      <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Last Name</th>
          <th>Email</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {
          applicants.map((data, i) => (
            <tr key={i} onClick={() => handleRowClick(data)} style={{ cursor: 'pointer' }}>
              <td>{data.ID}</td>
              <td>{data.name}</td>
              <td>{data.last_name}</td>
              <td>{data.email}</td>
              <td>{data.status}</td>
              <td>
                <select
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                  onChange={(e) => {
                    e.stopPropagation(); // Prevent row click event when button is clicked
                    handleSelectChange(data)(e)
                  }}
                  name="status"
                  id="status"
                >
                  <option value="Approuved">Approuved</option>
                  <option value="Rejected">Rejected</option>
                  <option value="Not Selected" selected>Not Selected yet</option>
                </select>
                <button
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent row click event when button is clicked
                    updateDB();
                  }}
                  className='btn btn-success update'
                >
                  Update
                </button>
              </td>
            </tr>
          ))
        }
      </tbody>
    </table>
    </div>
  );
}

export default Apps;
