/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import {
  CDBSidebar, CDBSidebarContent, CDBSidebarFooter, CDBSidebarHeader, CDBSidebarMenu, CDBSidebarMenuItem,
} from 'cdbreact';
import { NavLink } from 'react-router-dom';
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import axios from 'axios';

import { useLocation } from 'react-router-dom';
import Apps from '../components/Apps';
import Emails from '../components/Emails';
import { Helmet } from 'react-helmet';


const Dashboard = () => {
  /*URL */
  const location = useLocation()
  /* authentication*/
  const { logout } = useKindeAuth();

  /* get data from fRoM DB */
  const [applicants, setApplicants] = useState([]);


  useEffect(() => {
    axios.get('https://api.flysaturn.co/applications')

      .then(applicants => setApplicants(applicants.data))

      .catch(err => alert(err));
  }, [])

  return (
    <body className='body-dash'>
      <Helmet>
      <title>Admin Dashboard</title>

      </Helmet>
      <div className='sideboard'
        style={{ display: 'flex', height: 'auto', overflow: 'scroll initial' }}
      >
        <CDBSidebar textColor="white" backgroundColor="#1d3557">
          <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large"></i>}>
            <a className="text-decoration-none" style={{ color: 'inherit' }}> Admin Dashboard </a>

          </CDBSidebarHeader>

          <CDBSidebarContent textColor="white" className="sidebar-content">
            <CDBSidebarMenu>

              <NavLink exact to="/Dash/#emails" >
                <CDBSidebarMenuItem icon="table">E-mails</CDBSidebarMenuItem>
              </NavLink>
              <NavLink exact to="/Dash" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="user">Applications</CDBSidebarMenuItem>
              </NavLink>
              <NavLink exact to="/Dash/#analytics" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="chart-line">
                  Analytics
                </CDBSidebarMenuItem>
              </NavLink>


            </CDBSidebarMenu>
          </CDBSidebarContent>

          <CDBSidebarFooter style={{ textAlign: 'center' }}>
            <div
              style={{
                padding: '20px 5px',

              }}
            >
              SGN School
              <Button onClick={logout} className='loginB'  ><p>Logout</p></Button>

            </div>


          </CDBSidebarFooter>
        </CDBSidebar>
      </div>


      <div className='dash-table'>
        {
          location.pathname === '/Dash' && (
            <Apps />
          )

        }

        {
          location.pathname === '/Dash/' && (
            <Emails />
          )}

        {

        }


      </div>



    </body>
  );
};

export default Dashboard;