import './App.css';
import NavBar from './components/NavBar';
import HomePage from './components/Home';
import MyForm from './components/MyForm'
import Footer from './components/Footer';
import Cards from './components/cards';
import Options from './components/Options';
import Process from './components/Process';
import Contact from './components/Contact';
import Contacts from './pages/Contacts';
import Login from './pages/Login';
import Reply from './components/Reply'
//import notAuthed from './components/notAuthed';
import Details from './components/Details';
import Dashboard from './pages/Dashborad';
import ProtectedRoute from './components/ProtectedRoute';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { KindeProvider } from "@kinde-oss/kinde-auth-react";
import Test_backend_conn from './pages/test_backend_conn';



function App() {
  return (

    <Router>
      <KindeProvider
        clientId="9e612773e3824cfa9d3c60862c903ee2"
        domain="https://webdevibra.kinde.com"
        redirectUri="https://flysaturn.co/Dash"
        logoutUri="https://flysaturn.co/home"
      >


        <Routes>
          <Route index element={
            <div className="App"> <NavBar /> <HomePage /> <Cards /> <Options /> <Process /> <Contact /> <Footer />

            </div>
          } />

          <Route path="/home" element={
            <div className="App">  <NavBar /> <HomePage /> <Cards /> <Options /> <Process /> <Contact /> <Footer />

            </div>
          } />
          <Route
            path="/test" element={<Test_backend_conn/>}
          />
          <Route element={<ProtectedRoute />}>
            {" "}
            <Route path="/Dash" element={<div><NavBar /> <Dashboard /></div>} />
            <Route path='/details/:id' element={<Details />}></Route>
            <Route path="/Reply" element={<Reply />} >
              <Route path=":email" element={<Reply />} />
            </Route>

          </Route>

          <Route path="/notAuthed" element={<notAuthed />} />
          <Route path="/AppForm" element={<div><NavBar /> <MyForm /></div>} />
          <Route path="/Contacts" element={<div><NavBar /> <Contacts /></div>} />
          <Route path="/login" element={<Login />} />



        </Routes>
      </KindeProvider>
    </Router>

  );
}

export default App;
