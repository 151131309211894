import {useKindeAuth} from "@kinde-oss/kinde-auth-react";
import { Outlet } from "react-router";
import { Button } from "react-bootstrap";
import { Helmet } from 'react-helmet';

export default function ProtectedRoute(){
    const {isLoading, isAuthenticated, login} = useKindeAuth();

if(isLoading){
    return <div class="loader-container">
    <div class="loader"></div>
    <p>Loading...</p>
  </div>
}
if(!isLoading && !isAuthenticated){
    return(
        <div className="logb">
            <Helmet>
      <title>Authentication</title>

      </Helmet>
            <h1>
                Not Authenticated </h1>
                <Button className="lgbtn" onClick={login}>
                    Login
                </Button>
            
        </div>
    )
}

if(!isLoading && isAuthenticated){
    return<Outlet/>;
}



}