import Button from 'react-bootstrap/Button';


function Contact() {
    return (
       
<div id="contact" className="contact">
    <h3>
    Questions or opportunities? We are a message away!
    </h3>

     <Button href="/Contacts" className="loginB cb" id="bt" >Contact Us</Button> 

  

</div>
          

        
    );
}

export default Contact;