import { useState } from 'react';
import React, { useRef } from 'react';
import axios from "axios";
import { Helmet } from 'react-helmet';

function Contacts() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [receiver, setReceiver] = useState('');

    /* Add to DB */
    function AddtoDB() {
        return axios.post('https://api.flysaturn.co/Contacts', { name, email, message })
            .then(res => {
                console.log("Sent to DB successfully");
            })
            .catch(function (error) {
                if (error.response) {
                    console.log("Response error: " + error.response.data);
                    console.log("Response error status: " + error.response.status);
                    console.log("Response error headers: " + error.response.headers);
                } else if (error.request) {
                    console.log("Request error: " + error.request);
                } else {
                    console.log('Error: ' + error.message);
                }
                console.log("Config: " + error.config);
            });
    }

    /* Email JS */
    const form = useRef();

    const sendEmail = async (e) => {
        e.preventDefault();
        if (!message || !receiver || !name || !email) {
            return alert('Missing fields');
        }

        try {
            // Wait for DB insertion to complete before sending email
            await AddtoDB();

            // Send email after DB insertion
            await axios.post('https://api.flysaturn.co/Reply', { receiver, message });
            alert('Email sent successfully!');
        } catch (error) {
            console.error("Error occurred: ", error);
        }
    };

    function HandleClick(e) {
        setReceiver('recruitmentxc@flysaturn.co', 'confirmsb@flysaturn.co');
        sendEmail(e);
    }

    return (
        <div className="myform">
              <Helmet>
      <title>Contact Us</title>

      </Helmet>
            <h1 className='t'>Contact</h1>
            <div className="formbold-main-wrapper">
                <div className="formbold-form-wrapper">
                    <form ref={form}>
                        <div className="formbold-input-flex">
                            <div>
                                {/* Updated `for` to `htmlFor` */}
                                <label htmlFor="firstname" className="formbold-form-label">Name</label>
                                {/* Updated `class` to `className` */}
                                <input
                                    type="text"
                                    name="firstname"
                                    id="firstname"
                                    placeholder="Your first name"
                                    className="formbold-form-input"
                                    onChange={e => setName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="formbold-input-flex">
                            <div>
                                {/* Updated `for` to `htmlFor` */}
                                <label htmlFor="email" className="formbold-form-label">E-mail</label>
                                {/* Updated `class` to `className` */}
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    placeholder="example@email.com"
                                    className="formbold-form-input"
                                    onChange={e => setEmail(e.target.value)}
                                />
                            </div>
                        </div>
                        <div>
                            <div className="formbold-mb-3">
                                {/* Updated `for` to `htmlFor` */}
                                <label htmlFor="message" className="formbold-form-label">Message</label>
                                {/* Updated `class` to `className` */}
                                <textarea
                                    rows="6"
                                    name="message"
                                    id="message"
                                    className="formbold-form-input"
                                    onChange={e => setMessage(e.target.value)}
                                />
                            </div>
                        </div>
                    </form>
                    {/* Updated `class` to `className` */}
                    <button type="submit" onClick={HandleClick} className="formbold-btn">Send</button>
                </div>
            </div>
        </div>
    );
}

export default Contacts;
